import React from 'react'

function Featured() {
    return (
        <section id="services" className="what-we-do bg-light text-sky-500 text-1xl py-20">
            <div className="container mx-auto px-4 w-9/12 text-center item-center">
                <h2 className="text-3xl font-bold text-center mb-12">WHAT WE DO</h2>
                <p className="text-center mb-12 item-center text-2xl  text-white">
                    At the heart of our work is a commitment to innovation, quality, and customer satisfaction. <br/>Here’s how we make an impact:
                </p>

                <div className="grid md:grid-cols-3 gap-8 text-black">
                    <div className="p-4 bg-white rounded-lg shadow-md text-center">
                        <h3 className="text-3xl font-semibold mb-8">ADVISORY</h3>
                        <p className="text-justify">
                        Our Advisory Service is aimed at  entrepreneurs who are planning to develop and implement a solution to disrupt their industry. And they are willing to introduce a tokenization model into their business models. Our expertise guides them through a journey to discovery and ideas.
                        </p>
                    </div>
                    <div className="p-4 bg-white rounded-lg shadow-md text-center">
                        <h3 className="text-3xl font-semibold mb-8">BRAND</h3>
                        <p className="text-justify">
                        Our Brand Service offers comprehensive market strategies to help you implement it through a variety of services from web & creative design, SEO, content marketing, paid advertising, community building, reputation management, to investor & influencer outreach and more.
                        </p>
                    </div>
                    <div className="p-4 bg-white rounded-lg shadow-md text-center">
                        <h3 className="text-3xl font-semibold mb-8">DEVELOPMENT</h3>
                        <p className="text-justify">
                        Our Technology Service provides end-to-end services to help you launch crowdfunded campaign to raise capital for your blockchain projects. Our highly experienced and professional team can develop tailor-made solutions that guarantee a successful and secure crowd sale.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Featured