import React from 'react'


function Footer() {
    return (
        <footer class="text-gray-600 bg-gray-100">
            <div class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
                <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">Copyright &copy; 2025, BitEpoch.com — All Rights Reserved.
                </p>
                <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                    <a href='#about' class="text-gray-500">
                        WHO WE ARE
                    </a>
                    <a href='#services' class="ml-3 text-gray-500">
                        WHAT WE DO
                    </a>
                    <a href='#projects' class="ml-3 text-gray-500">
                        PROJECTS
                    </a>
                    <a href='#blogs' class="ml-3 text-gray-500">
                        BLOGS
                    </a>
                    <a href='#contact' class="ml-3 text-gray-500">
                        CONTACT
                    </a>
                </span>
            </div>
        </footer>
    )
}


export default Footer