// Import React (if necessary based on your React version)
import React from 'react';
import featured from "../assets/img/network-1024x648.png"

// About Us Component
const AboutUs = () => {
    return (
        <section id="about" className="about-us bg-dark text-white text-2xl py-5 mt-2">
            <div className="container mx-auto px-4  w-9/12">
                <h2 className="text-3xl font-bold text-center mb-12 text-blue-500">WHO WE ARE</h2>
                <p className="text-center mb-4">
                BitEpoch is an advisory service firm and a facilitator. We a focus companies and projects seeking to monetize their ideas via Tokenization. 
                </p>
                <p className="text-center mb-12">
                No venture or idea is too early for us. We take great pride in working with people who want to change the world. We believe that it's about the people - the people with great ideas.  
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="flex items-center">
                        <img 
                            src={featured} 
                            alt="Who We are" 
                            className="rounded-lg shadow-lg w-full h-auto object-cover"
                        />
                    </div>

                    <div className="flex flex-col justify-center">
                        <h3 className="text-4xl font-semibold mb-6">Our Mission</h3>
                        <p className="mb-4">
                        Our mission is to deliver innovative, high-quality solutions that empower businesses and individuals. We are committed to fostering growth, building strong relationships, and driving meaningful change globally.
                        </p>
                        <p  className="mb-10"/>
                        <h3 className="text-4xl font-semibold mb-6">Our Vision</h3>
                        <p>
                            We envision a future where technology seamlessly integrates with everyday
                            life to enhance experiences and drive progress. Join us as we strive to make
                            this vision a reality.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
