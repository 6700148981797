import React from 'react'

function Hero() {

      return (
        
        <section className="hero bg-dark text-light min-h-screen py-20 flex items-center justify-center text-center">
        <div className="container mx-auto px-4 w-9/12 justify-center text-center  items-center">
            <p className="mx-auto text-5xl font-bold text-sky-500 mb-12">Monetization via Tokenization™</p>
            <h1 className="text-2xl	text-white font-normal mb-8">as The Ultimate Game-Changer</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
                <div className="p-6 justify-center text-center ">
                    <p className="flex justify-center text-center ">
                   <svg class="h-28 w-28 text-slate-100 mb-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="3" y1="10" x2="3" y2="16" />  <line x1="21" y1="10" x2="21" y2="16" />  <path d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0" />  <line x1="8" y1="3" x2="9" y2="5" />  <line x1="16" y1="3" x2="15" y2="5" />  <line x1="9" y1="18" x2="9" y2="21" />  <line x1="15" y1="18" x2="15" y2="21" /></svg>         
                    </p>
                    <h3 className="text-2xl font-semibold  text-white mb-2">Technologies</h3>
                    </div>
                <div className="p-6  justify-center text-center">
                    <p className="flex justify-center text-center ">
                    <svg class="h-28 w-28 text-slate-100 mb-4"  viewBox="0 0 24 24" fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="12 2 2 7 12 12 22 7 12 2" />  <polyline points="2 17 12 22 22 17" />  <polyline points="2 12 12 17 22 12" /></svg>
                        
                    </p>
                    <h3 className="text-2xl font-semibold  text-white mb-2">Platforms</h3>
                    
                </div>
                <div className="p-6  justify-center text-center">
                    <p className="flex justify-center text-center ">
                    <svg class="h-28 w-28 text-slate-100 mb-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />  <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />  <polyline points="12 8 7 3 3 7 8 12" />  <line x1="7" y1="8" x2="5.5" y2="9.5" />  <polyline points="16 12 21 17 17 21 12 16" />  <line x1="16" y1="17" x2="14.5" y2="18.5" /></svg>
                        
                    </p>
                    <h3 className="text-2xl font-semibold text-white first-line:mb-2">Developments</h3>
                    
                </div>
            </div>
            
        </div>
         
    </section>
        
        

      );
}

export default Hero;
