import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo-190-60.png"

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all ${
        isScrolled ? "bg-gray-700 text-white shadow-lg" : "bg-sky-950 text-white"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Brand Logo */}
          <div className="flex-shrink-0">
            <a
              href="/"
              className={`text-3xl font-bold ${
                isScrolled ? "text-white" : "text-gray-900"
              }`}
            >
              <img
                src={logo}
                alt="BitEpoch"
                className="max-w-max max-h-16 object-cover rounded-md relative"
              />
            </a>
          </div>

          {/* Desktop Links */}
          <div className="hidden md:flex space-x-4">
            <a
              href="#about"
              className="hover:text-blue-500 px-3 py-2 rounded-md text-1xl font-medium"
            >
              WHO WE ARE
            </a>
            <a
              href="#services"
              className="hover:text-blue-500 px-3 py-2 rounded-md text-1xl font-medium"
            >
              WHAT WE DO
            </a>
            <a
              href="#projects"
              className="hover:text-blue-500 px-3 py-2 rounded-md text-1xl font-medium"
            >
              PROJECTS
            </a>
            <a
              href="#blogs"
              className="hover:text-blue-500 px-3 py-2 rounded-md text-1xl font-medium"
            >
              BLOG
            </a>
            <a
              href="#contact"
              className="hover:text-blue-500 px-3 py-2 rounded-md text-1xl font-medium"
            >
              CONTACT
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-blue-500 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Dropdown Menu */}
        {isMenuOpen && (
          <div
            className="md:hidden bg-white border border-gray-200 rounded-lg shadow-lg mt-2"
          >
            <a
              href="/"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              Home
            </a>
            <a
              href="#about"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              Who We Are
            </a>
            <a
              href="#services"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              What We Do
            </a>
            <a
              href="#projects"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              Projects
            </a>
            <a
              href="#blogs"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              Blogs
            </a>
            <a
              href="#contact"
              className="block text-gray-700 hover:bg-gray-100 px-4 py-2"
            >
              Contact
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

