import React from 'react'

import Navbar from './components/navbar'
import Hero from './components/hero'
import Discover from './components/discover'
import Form from './components/form'
import Blog from './components/blog'
import Footer from './components/footer'
import Featured from './components/featured'
import ParticlesComponent from './components/particles'
import AboutUs from './components/aboutus'

function App() {
  return (
    <>
       
        <ParticlesComponent />
        <Navbar />
        <Hero />
        <AboutUs/>
        <Featured />
        <Discover />
        <Blog />
        <Form />
        <Footer />    
    </>
  )
}


export default App