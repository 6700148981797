import React from 'react'
import img1 from "../assets/img/img1.avif"
import img2 from "../assets/img/img2.png"
import img3 from "../assets/img/img3.jpg"
import img4 from "../assets/img/img4.jpg"



function Blog() {
    return (
        <section id="blogs" class="text-gray-600 mt-20 lg:mb-20 mb-0 body-font" >
            <h2 class="flex justify-center text-3xl font-bold text-sky-500">CATCH UP TO INDUSTRY NEWS</h2>
            <h1 class="flex justify-center text-2xl text-white font-normal mb-2 lg:mb-7">Recent entries</h1>
            <div class="container px-5 py-12 lg:py-24 mx-auto flex flex-wrap w-9/12">


                <div class="rounded-lg h-auto bg-gray-100 lg:w-1/2 w-full mb-4 lg:mb-0">
                    <img alt="feature" class="object-cover object-center h-8/12 w-full" src={img1} />
                    <div className='flex items-center justify-center px-6 py-3.5'>
                        <div className='flex flex-col my-auto'>
                            <h2 className='text-2xl'>US crypto industry eyes possible day-one Trump executive orders</h2>
                            <p className='text-base w-11/12'>The cryptocurrency industry is pushing President-elect Donald Trump's team to kick start his promised crypto policy overhaul when he takes office next month with executive orders that would help push tokens mainstream, according to industry officials.
                            Trump plans to issue a flurry of executive orders and directives on everything from immigration to energy on his first day in office on Jan. 20...</p>
                        </div>
                    </div>


                </div>
                <div class="flex flex-col flex-wrap lg:w-1/2 lg:pl-2.5 lg:text-left text-center">
                    <div class="rounded-lg bg-gray-100 p-2.5 flex mb-4 lg:items-start items-center">
                        <div class="w-1/3 h-full inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                            <img alt="feature" class="object-cover object-center h-full w-full" src={img2} />
                        </div>
                        <div class="pl-5 w-2/3 h-auto">
                            <h2 className="text-2xl mb-3">Trump's Election Win Sparks Surge in Gen Z Crypto Investments</h2>
                            <p className='text-base'>A surge in Gen Z crypto investors has taken the market by storm, and the catalyst may just be political. Following Donald Trump’s election win in November 2024...</p>
                        </div>
                    </div>
                    <div class="rounded-lg bg-gray-100 p-2.5 flex mb-4 lg:items-start items-center">
                        <div class="w-1/3 h-full inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                            <img alt="feature" class="object-cover object-center h-full w-full" src={img3} />
                        </div>
                        <div class="pl-5 w-2/3 h-auto">
                            <h2 class="text-2xl mb-3">Experts reveal game-changing ways cryptocurrency can boost local economies — do the perks outweigh the cost?</h2>
                            <p class=" text-base">As more people become aware of the negative environmental impacts of advancements in technology, certain industries and businesses are looking to pivot and remake their images in the name of the green transition...</p>
                        </div>
                    </div>
                    <div class="rounded-lg bg-gray-100 p-2.5 flex mb-4 lg:items-start items-center">
                        <div class="w-1/3 h-full inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                            <img alt="feature" class="object-cover object-center h-full w-full" src={img4} />
                        </div>
                        <div class="pl-5 w-2/3 h-auto">
                            <h2 class="text-2xl mb-3">Where Analysts Think Bitcoin is Headed in 2025</h2>
                            <p class="text-base">
                            Analysts are bullish that demand from spot bitcoin ETFs and the possible adoption of a U.S. bitcoin reserve could help bitcoin to new highs...</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >


    )
}


export default Blog