import React from 'react'
import openco2x from "../assets/img/openco2x.png"
import urbanx from "../assets/img/urbanx.png"
import murmo from "../assets/img/murmo.png"
import wisdomx from "../assets/img/wisdomx.png"

function Discover() {
    return (
        <section id="projects" class="text-gray-600 body-font" >
            <div class="container px-5 py-24 mx-auto w-9/12">
                <h2 className='text-3xl font-bold text-blue-500 flex justify-center mb-12'>PROJECTS</h2>
                <h1 className='flex justify-center text-2xl	text-white font-normal mb-12'>Monetize your real world assets with us</h1>
                <div class="flex flex-wrap -m-4">
                    <div class="xl:w-1/4 md:w-1/2 sm:w-5/6 mx-auto lg:mx-0 p-4">
                        <img class="h-auto rounded w-full object-cover object-center mb-6" src={openco2x} alt="content" />
                        <h3 class="flex justify-center text-xl text-white">A Global Carbon Trade Platform</h3><br/>
                        <h2 class="flex justify-center text-justify text-base text-white mb-4">serves as an international marketplace for the buying, selling, and trading of carbon credits. These credits represent reductions in greenhouse gas emissions, enabling organizations and governments to meet their climate targets under agreements like the Paris Accord.</h2>
                   </div>
                    <div class="xl:w-1/4 md:w-1/2 p-4 sm:w-5/6 mx-auto lg:mx-0 ">
                        <img class="h-auto rounded w-full object-cover object-center mb-6" src={urbanx} alt="content" />
                        <h3 class="flex justify-center text-xl text-white">A Global Crowdfunding Real Estate Platform</h3><br/>
                        <h2 class="flex justify-center text-justify text-base text-white mb-4">is an online platform that enables individuals from around the world to collectively invest in urban real estate projects. It democratizes real estate investing by lowering the entry barriers and allowing small-scale investors to access opportunities previously available only to institutional players.</h2>
                    </div>
                    <div class="xl:w-1/4 md:w-1/2 p-4 sm:w-5/6 mx-auto lg:mx-0 ">
                        <img class="h-auto rounded w-full object-cover object-center mb-6" src={murmo} alt="content" />
                        <h3 class="flex justify-center text-xl text-white">A Distributed GPU Platform for AI</h3><br/>
                        <h2 class="flex justify-center text-justify text-base text-white mb-4">leverages blockchain technology to create a decentralized network for sharing GPU computing power. This platform is designed to meet the growing demand for computational resources in AI and machine learning applications by efficiently utilizing idle GPU capacity worldwide.</h2>
                    </div>
                    <div class="xl:w-1/4 md:w-1/2 p-4 sm:w-5/6 mx-auto lg:mx-0 ">
                        <img class="h-auto rounded w-full object-cover object-center mb-6" src={wisdomx} alt="content" />
                        <h3 class="flex justify-center text-xl text-white">A Distributed Voting Platform</h3><br/>
                        <h2 class="flex justify-center text-justify text-base text-white mb-4">combines the wisdom of crowds intelligence with blockchain technology to create a secure, transparent, and decentralized decision-making system. This platform enables individuals or organizations to gather collective insights and make data-driven decisions in various scenarios.</h2>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Discover